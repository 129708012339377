.container {
  height: 100vh;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 2rem;
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 75px 75px 75px 75px 75px;
}

.text {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.5rem;
  font-family: 'Quicksand', sans-serif;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 3.5vw;
  position: absolute;
  bottom: 0;
  margin-bottom: 0.5rem;
  font-family: 'Quicksand', sans-serif;
  padding: 0 2rem;
}

.mobile-link {
  color: white;
}
