.app {
  width: 100%;
  height: 100%;
}

.logo {
  height: 35px;
  width: auto;
  margin-top: 5px;
  margin-left: 15px;
}

.main-logo {
  font-family: 'Quicksand', sans-serif;
  font-optical-sizing: auto;
  font-size: 33px;
  font-weight: 500;
  margin-left: 15px;
  font-style: normal;
}

.app-grid {
  transition: filter 0.3s ease;
  display: grid;
  height: 100vh;
  grid-template-columns: 1fr;
  grid-template-rows: 60px 1fr;
}

.notes-container {
  margin: 0 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blur-background {
  filter: blur(2px);
}

:root {
  --yellow-2: #ffefb7;
  --gray-2: #cccccc;
}

.navbar {
  width: 100%;
  height: 60px;
  display: grid;
  grid-template-columns: 1fr 50px 50px 50px 50px;
  grid-template-rows: auto;
  /* border-bottom: 2px solid black; */
  position: fixed;
}

.navbar > div:first-child {
  display: flex;
  align-items: center;
  justify-content: left;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  min-width: 0px;
  min-height: 0px;
  font-size: xx-large;
}

.navbar > div:not(:first-child) {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  min-width: 0px;
  min-height: 0px;
  font-size: xx-large;
}

.navbar-icon {
  font-size: x-large;
  padding: 6px;
}

.privacy {
  position: absolute;
  bottom: 0;
  right: 1;
  margin-right: 10px;
  color: #888888;
  text-decoration: none;
}
