.thanks {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  width: 100%;
  height: 100vh;
}

.thanks-container {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto auto 1fr;
  height: 100%;
}

.thanks-text {
  font-size: 100%;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
}

.thanks-button {
  font-size: 100%;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  border: 1px solid white;
  padding: 5px;
}
