.note {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 10px 20px 20px 1fr 50px 10px;
  grid-template-rows: 20px 1fr;
  grid-template-areas: 
      "i header-left-1 header-left-2 header-center header-right j"
      ". note-textarea note-textarea note-textarea note-textarea .";
  border: 1px solid black;
  border-radius: 10px;
  margin: 0;
  padding: 0;
  background-color: lightyellow;
  box-shadow: 2px 2px rgba(0, 0, 0, 30%);
}

.note > div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  min-width: 0;
  min-height: 0;
}

.header-color {
  background-color: var(--yellow-2);
}

.note-textarea {
  grid-area: note-textarea;
  border: none;
  outline: none;
  background: none;
  resize: none;
  height: calc(100% - 15px);
  width: calc(100% - 10px);
  font-family: monospace;
  padding: 0px;
  margin: 0px;
  line-height: 1;
  white-space: pre-wrap;
}

.left-align {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.note-textarea-wrapper {
  grid-area: note-textarea;
  width: 100%;
  height: 100%;
  display: flex;
}

.i {
  grid-area: i;
  background-color: var(--yellow-2);
  border-top-left-radius: 10px;
}

.j {
  grid-area: j;
  background-color: var(--yellow-2);
  border-top-right-radius: 10px;
}

.header-left-1 {
  grid-area: header-left-1;
  background-color: var(--yellow-2);
}

.header-left-2 {
  grid-area: header-left-2;
  background-color: var(--yellow-2);
}

.header-center {
  grid-area: header-center;
  background-color: var(--yellow-2);
}

.header-right {
  grid-area: header-right;
  background-color: var(--yellow-2);
}


.timer {
  color: black;
}

button {
  background: none;
  border: none;
  padding: 0px;
  margin: 0px;
}

button:active {
  color: #666666;
}

::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

/* The handle of the scrollbar */
::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px;
  /* border: 2px solid white; */
}

/* On hover */
::-webkit-scrollbar-thumb:hover {
  background-color: grey;
}

/* p {
  margin: 0px;
  padding: 0px;
} */