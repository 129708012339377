.auth {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
}

.auth-container {
  display: grid;
  grid-template-rows: 100px auto;
  grid-template-columns: 350px;
  border: 2px solid black;
}

.auth-container > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-logo {
  height: 100px;
  width: auto;
}

.inner-auth {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.inner-auth-container {
  display: grid;
  grid-template-rows: 2fr 1fr auto 2fr;
  grid-template-columns: 1fr;
  height: 100%;
  width: 100%;
}

.inner-auth-container > * {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.inner-auth-header {
  margin: 0px;
  font-size: 1.5em;
  font-weight: bolder;
  font-style: italic;
}

.inner-auth-error {
  height: 30px;
  width: 100%;
  font-size: .9em;
  color: red;
}

.main-button {
  border: 1px solid black;
  border-radius: 0px;
  padding: 5px;
  margin-top: 20px;
  background-color: black;
  color: white;
  width: 100%;
}

.main-button:active {
  background-color: white;
  color: black;
}

/* The above & below are not DRY, refactor */

/* This is the property that permits a style change for submit buttons onClick AND onKeyDown */

.main-button-inverse {
  border: 1px solid black;
  border-radius: 0px;
  padding: 5px;
  margin-top: 20px;
  background-color: white;
  color: black;
  width: 100%;
}

.inner-auth label {
  font-size: 0.8em;
  font-weight: bold;
}

.link-button {
  font-size: 0.8em;
  text-decoration: none;
}

input {
  background-color: transparent; /* Makes the background transparent */
  border: none; /* Removes all the borders */
  border-bottom: 1px solid black; /* Adds a solid line just at the bottom */
  outline: none; /* Removes the outline */
  width: 100%;
  margin: 5px 0px 0px 0px; /* top right bottom left */
}

form {
  width: 70%;
}

.form-error {
  width: 100%;
  height: auto;
  font-size: 12px;
  color: red;
}
