.forgot {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.forgot-container {
  display: grid;
  grid-template-areas:
    'forgot-header'
    'forgot-subheader'
    'forgot-email'
    'forgot-button';
}

.forgot-container > div {
  margin: 2px 0px;
}

.forgot-header {
  grid-area: forgot-header;
}

.forgot-subheader {
  grid-area: forgot-subheader;
  margin: 5px 0px;
}

.forgot-email {
  grid-area: forgot-email;
}

.forgot-button {
  grid-area: forgot-button;
}

.forgot-button > button {
  border: 1px solid black;
  border-radius: 2px;
  padding: 5px;
  margin: 5px 0px;
}
